<script lang="ts" setup>
import Navbar from '@/views/front-pages/navbar-not-login.vue'

const { injectSkinClasses } = useSkins()

// ℹ️ This will inject classes in body tag for accurate styling
injectSkinClasses()
</script>

<template>
  <Navbar />
  <div class="layout-wrapper layout-blank">
    <slot />
  </div>
</template>

<style>
.layout-wrapper.layout-blank {
  flex-direction: column;
}
</style>
